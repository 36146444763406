
require('../scss/app.scss');

// vendors

const $ = require('jquery');
global.$ =  $;
global.jQuery = $;

require('argon-dashboard/assets/js/plugins/bootstrap/dist/js/bootstrap.bundle.min.js');

require('argon-dashboard/assets/js/plugins/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js');
require('argon-dashboard/assets/js/plugins/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js');

require('argon-dashboard/assets/js/argon-dashboard.js');

require('jquery-mask-plugin/dist/jquery.mask.js');

require('select2/dist/js/select2.js');
require('select2/dist/js/i18n/fr.js');

const Quill = require('quill/dist/quill.js');
window.Quill = Quill;
const QuillImageResize = require('quill-image-resize-module/image-resize.min.js');
Quill.register('imageResize', QuillImageResize);

// vendor - init
$(document).ready(function() {
    // bootstrap-datepicker
    $('.datepickerContainer').find('input.form-control').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        clearBtn: true,
    });
    // jQuery Mask
    $('.date-mask').mask('00/00/0000', {
        placeholder: '__/__/____',
    });

    $('.time-mask').mask('00:00:00', {
        placeholder: '__:__:__',
    });

    $('.datetime-mask').mask('00/00/0000 00:00:00', {
        placeholder: '__/__/____ __:__:__',
    });

    $('.phone-mask').mask('00 00 00 00 00', {
        placeholder: '__ __ __ __ __',
    });

    // Select2
    $('select:not([data-sonata-select2="false"]):not([data-select2="false"]):not(.select2-hidden-accessible)').select2({
        language: 'fr',
        placeholder: ''
    });

    $('.search-address').select2({
        ajax: {
            url: 'https://api-adresse.data.gouv.fr/search/',
            dataType: 'json',
            delay: 250,
            language: 'fr',
            data: params => {
                return {
                    q: params.term, // search term
                    type: 'street',
                    lat: 43.5911679,
                    lon: 5.3102505,
                    limit: 20
                };
            },
            processResults: data => {

                /*
                 * parse the results into the format expected by Select2
                 * since we are using custom formatting functions we do not need to
                 * alter the remote JSON data, except to indicate that infinite
                 * scrolling can be used
                 */
                var resData = [];
                data.features.forEach(function(value) {
                    resData.push(value);
                });
                return {
                    results: $.map(resData, function(item) {
                        return {
                            text: item.properties.label,
                            id: item.properties.id,
                            lon: item.geometry.coordinates[0],
                            lat: item.geometry.coordinates[1],
                            city: item.properties.city,
                            postcode: item.properties.postcode
                        };
                    })
                };
            },
            cache: true
        },
        minimumInputLength: 4
    });

    $('.search-address').on('select2:select', function (event) {
        let data = event.params.data;

        $('.hidden.address').val(data.text);
        $('.hidden.longitude').val(data.lon);
        $('.hidden.latitude').val(data.lat);
    });
    $('.quill').each(function () {
        let quill = new Quill($(this).get(0), {
            theme: $(this).data('theme'),
            debug: $(this).data('debug'),
            readOnly: $(this).data('readOnly'),
            placeholder: $(this).data('placeholder'),
            modules: {
                imageResize: {
                    displaySize: true
                },
                toolbar: [
                    // [{ header: [] }],
                    [{ font: [] }, { size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ indent: '+1' }, { indent: '-1' }, { list: 'ordered' }, { list: 'bullet' }],
                    [{ 'header': [2, 3] }, 'blockquote', 'code-block'],
                    ['link', 'image', 'video', 'formula'],
                    ['clean']
                ]
            }
        });
        let textareaId = $(this).data('id');
        quill.on('text-change', function(/* delta, oldDelta, source */) {
            $('#'+textareaId).val(quill.root.innerHTML);
        });
    });
});
